"use client";

import {Center, Stack, Text, Title} from "@mantine/core";
import Lottie from "lottie-react";
import loader from "../../public/images/404-error.json";
import {useRouter} from "next/navigation";
import {useCallback} from "react";
import {useTiltedMobile} from "@/components/common/hooks/responsive-utils";


export default function NotFoundPage() {
    const isTiltedMobile = useTiltedMobile();
    const router = useRouter();
    const onAnimationComplete = useCallback(() => {
        router.push('/');
    }, [router]);

    return (
        <div style={{
            flex: 1,
            flexDirection: 'column',
            minWidth: '100%',
            minHeight: '100%',
            overflow: 'hidden'
        }}>
            <Center>
                <Stack gap={'md'} align={'center'} style={{flex: 1, minHeight: '100%', overflow: 'hidden'}}>
                    <Lottie animationData={loader}
                            style={{height: isTiltedMobile ? '40vh' : '65vh'}}
                            loop={false}
                            onComplete={onAnimationComplete}
                    />
                    <Stack gap={'sm'} align={'center'} style={{overflow: 'hidden'}}>
                        <Title order={1}>Page not found</Title>
                        <Text size={'md'} c={'dimmed'}>Redirecting you to the home page...</Text>
                    </Stack>
                </Stack>
            </Center>
        </div>
    );
}
